"use client";

import {useState} from "react";
import dynamic from "next/dynamic";
import {z} from "zod";

import Icon from "@/components/atoms/Icon";
import {Loader} from "@/components/atoms/Loader";
import {Sausage} from "@/components/atoms/Sausage";
import {Hero} from "@/components/blocks/Hero";
import {LocationValueSelectProps} from "@/components/forms/controls/NewLocationInput";
import Select from "@/components/forms/controls/Select";
import Form from "@/components/forms/Form";
import {Filters} from "@/components/organisms/Filters";
import {FiltersSidebar} from "@/components/organisms/FiltersSidebar";
import {NewLocationInput} from "@/components/forms/controls/NewLocationInput";
import {SearchQueryInput} from "@/components/forms/controls/SearchQueryInput";
import {Facet} from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className";
import {useGetParams, useRefreshSearchParams} from "@/helpers/search";
import {mapLocaleToLanguage} from "@/helpers/xmp";
import {
  AllowedJobSearchParams,
  AllowedLanguages,
  AllowedOfficeSearchParams,
} from "@/types/generic";
import headerImage from "@/assets/images/jobs-header.jpg";
import {useTranslation} from "@/i18n/client";

const NoSSRSearchQueryInput = dynamic(async () => SearchQueryInput, {
  ssr: false,
});

export const DEFAULT_DISTANCE = "15";

const schema = z.object({
  [AllowedJobSearchParams.Query]: z.string().optional(),
  [AllowedJobSearchParams.City]: z.string().optional(),
  [AllowedJobSearchParams.Lat]: z.string().optional(),
  [AllowedJobSearchParams.Lng]: z.string().optional(),
  [AllowedJobSearchParams.MaxDistance]: z.string().optional(),
  [AllowedJobSearchParams.ContractType]: z.array(z.string()).optional(),
  [AllowedJobSearchParams.Regime]: z.array(z.string()).optional(),
  [AllowedJobSearchParams.Sector]: z.array(z.string()).optional(),
  [AllowedJobSearchParams.Niche]: z.array(z.string()).optional(),
  [AllowedJobSearchParams.Language]: z.array(z.string()).optional(),
});

type Schema = z.infer<typeof schema>;

export const distanceOptions = [
  {value: "5", label: "5 km"},
  {value: "10", label: "10 km"},
  {value: "15", label: "15 km"},
  {value: "20", label: "20 km"},
  {value: "50", label: "50 km"},
];

const filterOrder = [
  AllowedJobSearchParams.Regime,
  AllowedJobSearchParams.ContractType,
  AllowedJobSearchParams.Language,
  AllowedJobSearchParams.Niche,
  AllowedJobSearchParams.Sector,
];

interface SearchJobsFilterFormProps {
  children?: React.ReactNode;
  filters: Facet[];
  i18n: {
    active_filters: string;
    all_filters: string;
    clear_filters: string;
    clear_recent_searches: string;
    close_sidebar: string;
    jobs: string;
    new: string;
    recent_searches: string;
    search_in: string;
    search_location_placeholder: string;
    search_query_label: string;
    search_query_placeholder: string;
    show: string;
    title: string;
    use_current_location: string;
  };
  locale: AllowedLanguages;
  resultCount?: number;
}

export const SearchJobsFilterForm = ({
                                       resultCount,
                                       filters,
                                       children,
                                       i18n,
                                       locale,
                                     }: SearchJobsFilterFormProps) => {
  const params = useGetParams();
  const [clickedFilter, setClickedFilter] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const {t} = useTranslation(locale);

  const defaultValues: Schema = {
    [AllowedJobSearchParams.Query]:
    params.get(AllowedJobSearchParams.Query) ?? "",
    [AllowedJobSearchParams.City]:
    params.get(AllowedJobSearchParams.City) ?? "",
    [AllowedJobSearchParams.Lat]: params.get(AllowedJobSearchParams.Lat) ?? "",
    [AllowedJobSearchParams.Lng]: params.get(AllowedJobSearchParams.Lng) ?? "",
    [AllowedJobSearchParams.MaxDistance]:
    params.get(AllowedJobSearchParams.MaxDistance) ?? DEFAULT_DISTANCE,
    [AllowedJobSearchParams.ContractType]:
    params.get(AllowedJobSearchParams.ContractType)?.split("|") ?? [],
    [AllowedJobSearchParams.Regime]:
    params.get(AllowedJobSearchParams.Regime)?.split("|") ?? [],
    [AllowedJobSearchParams.Sector]:
    params.get(AllowedJobSearchParams.Sector)?.split("|") ?? [],
    [AllowedJobSearchParams.Niche]:
    params.get(AllowedJobSearchParams.Niche)?.split("|") ?? [],
    // If a user did not select language manually and a default language is "en" we want to show jobs on all languages (do not apply language filter in this case)
    [AllowedJobSearchParams.Language]: params.get(AllowedJobSearchParams.Language)?.split("|") ?? locale === AllowedLanguages.En ? [] : [mapLocaleToLanguage[locale]],
  };

  const {isPending, updateFilter, updateQuery, form, selectedFilters} =
    useRefreshSearchParams(
      schema,
      [
        AllowedJobSearchParams.ContractType,
        AllowedJobSearchParams.Sector,
        AllowedJobSearchParams.Regime,
        AllowedJobSearchParams.Niche,
        AllowedJobSearchParams.Language,
        AllowedJobSearchParams.Lat,
        AllowedJobSearchParams.Lng,
      ],
      defaultValues,
    );

  const handleSubmit = (query: Schema) => {
    updateFilter(AllowedJobSearchParams.Job, '');
    updateFilter(AllowedJobSearchParams.Office, '');
    updateFilter(AllowedJobSearchParams.functionGroup, '');
    updateQuery(
      query?.query ?? "",
      query?.city ?? "",
      query?.max_distance ?? "",
      1,
      "jobSearchHistory",
      3,
    );
  };

  const updateLocation = ({
                            latitude,
                            longitude,
                            location,
                          }: LocationValueSelectProps) => {
    updateFilter(AllowedOfficeSearchParams.Lat, latitude.toString());
    updateFilter(AllowedOfficeSearchParams.Lng, longitude.toString());
    updateFilter(AllowedOfficeSearchParams.City, location);

    updateFilter(AllowedJobSearchParams.Query, form.getValues().query ?? '');
    updateFilter(AllowedOfficeSearchParams.MaxDistance, form.getValues().max_distance ?? '');
  };

  const filterCount = (type: string) => {
    return selectedFilters.filter((filter) => filter.type === type).length;
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFiltersOpen(true);

    const key = event.currentTarget.dataset.target;
    if (key) {
      setClickedFilter(key);
    }
  };

  interface FilterSausageProps {
    className?: string;
    name: string;
  }

  const FilterSausage = ({name, className}: FilterSausageProps) => (
    <Sausage
      count={filterCount(name)}
      active={filterCount(name) > 0}
      onClick={handleClickFilter}
      key={name}
      data-target={name}
      className={className}
    >
      {t(`filter.category.${name}`)}
    </Sausage>
  );

  const showSidebar = (resultCount !== 0 || selectedFilters?.length > 0);

  return (
    <>
      <Hero
        titleClassName="!mb-16 lg:!mb-28"
        className="mb-0 lg:mb-0 xl:h-[450px]"
        title={i18n.title}
        image={{
          url: headerImage.src
        }}
      />

      <Form form={form} onSubmit={handleSubmit} className="relative z-1">
        <div className="pb-16">
          <div className="container">
            <div className="grid grid-cols-12">
              <div className="col-span-12 xl:col-span-10 xl:col-start-2">
                <div className="-mt-10 mb-10 rounded-lg bg-yellow p-4 lg:-mt-16 lg:mb-16 lg:px-10 lg:py-8 xl:mb-24">
                  <div className="grid grid-cols-24 gap-2 lg:!gap-6 ">
                    <NoSSRSearchQueryInput
                      className="col-span-24 h-12 sm:col-span-11 md:col-span-12 lg:col-span-8 lg:h-16 xl:col-span-9 mb-3 md:mb-0"
                      i18n={{
                        clear_recent_searches: i18n.clear_recent_searches,
                        label: i18n.search_query_label,
                        new: i18n.new,
                        placeholder: i18n.search_query_placeholder,
                        recent_searches: i18n.recent_searches,
                      }}
                      searchHistory={true}
                    />
                    <div
                      className="col-span-24 flex h-12 sm:col-span-13 md:col-span-12 lg:col-span-9 lg:h-16 xl:col-span-8">
                      <NewLocationInput
                        onSelectValue={updateLocation}
                        className="form-input-lg w-full"
                        inputClassName="form-input-lg !rounded-r-none rounded-l-lg focus:ring-0 ring-0"
                        inputName="city"
                        i18n={{
                          placeholder: i18n.search_location_placeholder,
                          use_current_location: i18n.use_current_location,
                        }}
                        allowCurrentLocation={true}
                        locale={locale}
                      />

                      <Select
                        label={t('jobs.search_location.max_distance')}
                        name="max_distance"
                        className="divider-l flex items-center"
                        inputClassName="!rounded-l-none rounded-r-lg pl-4 pr-6 lg:pr-10 focus:ring-0 w-[88px] lg:!w-24 text-sm ring-0"
                        defaultValue={DEFAULT_DISTANCE}
                        options={distanceOptions}
                        hiddenLabel={true}
                        onChange={(e) => {
                          if (e?.target) {
                            updateFilter(
                              AllowedOfficeSearchParams.MaxDistance,
                              e.target.value,
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="xxl:pl-4 col-span-7 hidden lg:block">
                      <button
                        type="submit"
                        className={"btn btn-green btn-lg h-12 w-full lg:h-16"}
                      >
                        {i18n.search_in}
                      </button>
                    </div>
                  </div>

                  <div className="mt-4 flex flex-wrap gap-2 lg:hidden">
                    <FilterSausage name={AllowedJobSearchParams.Regime}/>
                    <FilterSausage name={AllowedJobSearchParams.ContractType}/>
                    <FilterSausage name={AllowedJobSearchParams.Language}/>
                    <FilterSausage
                      name={AllowedJobSearchParams.Niche}
                      className="hidden sm:inline-flex"
                    />
                    <FilterSausage
                      name={AllowedJobSearchParams.Sector}
                      className="hidden sm:inline-flex"
                    />

                    <Sausage
                      className="ml-auto"
                      onClick={() => {
                        setClickedFilter(null);
                        setFiltersOpen(true);
                      }}
                    >
                      {i18n.all_filters}
                      <Icon name={"sliders"} className="h-4 w-4"/>
                    </Sausage>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:grid grid-cols-12 gap-8 xl:gap-0 mb-20">
              {showSidebar && (
                <div className="hidden lg:col-span-4 lg:block xl:col-span-3">
                  <Filters
                    submitHandler={handleSubmit}
                    updateFilter={updateFilter}
                    selectedFilters={selectedFilters}
                    resultCount={resultCount}
                    filters={filters}
                    filterOrder={filterOrder}
                    entityTitle={"jobs"}
                    i18n={{
                      active_filters: i18n.active_filters,
                      clear_filters: i18n.clear_filters,
                    }}
                    locale={locale}
                  />
                </div>
              )}

              <div
                className={cn(
                  "lg:col-span-8",
                  showSidebar ? "lg:col-start-5" : "lg:col-start-3",
                )}
              >
                {isPending ? (
                  <Loader locale={locale} className="my-10"/>
                ) : (
                  children
                )}
              </div>
            </div>
          </div>
        </div>

        <FiltersSidebar
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
          clickedFilter={clickedFilter ?? undefined}
          i18n={{
            clear_filters: i18n.clear_filters,
            close_sidebar: i18n.close_sidebar,
            show: i18n.show,
            entity_title: i18n.jobs,
          }}
          resultCount={resultCount ?? undefined}
        >
          <Filters
            submitHandler={handleSubmit}
            updateFilter={updateFilter}
            selectedFilters={selectedFilters}
            resultCount={resultCount}
            filters={filters}
            filterOrder={filterOrder}
            idPrefix={"mobile_"}
            i18n={{
              active_filters: i18n.active_filters,
              clear_filters: i18n.clear_filters,
            }}
          />
        </FiltersSidebar>
      </Form>
    </>
  );
};
