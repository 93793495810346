import {useEffect, useState} from "react";

const useTypewriter = (words: string[], speed = 50, delay = 1000): string => {

  const [wordIndex, setWordIndex] = useState(0);
  const [text, setText] = useState('');

  useEffect(() => {
    const timer = setTimeout(type, speed);
    return () => clearTimeout(timer);
  }, [wordIndex, text]);

  function type() {
    // Current word
    const currentWord = words[wordIndex];
    // Create the new text
    setText(current => currentWord.substring(0, current.length + 1));

    if(text === currentWord) {
      setTimeout(() => {
        setWordIndex((current) => (current + 1) % words.length)
        setText('');
      }, delay);
    }
  }

  return text;
}

export default useTypewriter;