import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/node_modules/.pnpm/next@14.1.3_@babel+core@7.24.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/assets/images/charlotte.png");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/assets/images/logo-vivaldis.svg");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/assets/images/social_share.png");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/atoms/DrupalImage.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/blocks/TranslatedPaths.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/forms/FaqSearchForm.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/forms/HomeSearchJobsForm.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/forms/NewsletterForm.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/molecules/JobsList.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/molecules/SaveJobButton.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/organisms/Share.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/FindJobsParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/FiveBlocksParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/HtmlParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/MediaTextParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/NewsletterFormParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/ParagraphTwoColumnBlock.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/ParagraphTwoTextColumns.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/QuoteParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/StatisticParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/TeaserParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/TestimonialsParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/ThreeColumnBlockParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/ThreeImagesParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vivaldis/vivaldis_interim/frontend/src/components/paragraphs/VideoParagraph.tsx")