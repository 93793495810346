'use client';

import Form from "@/components/forms/Form";
import {useZodForm} from "@/hooks/useZodForm";
import {z} from "zod";
import {AllowedJobSearchParams, AllowedLanguages} from "@/types/generic";
import {LocationValueSelectProps, NewLocationInput} from "@/components/forms/controls/NewLocationInput";
import Select from "@/components/forms/controls/Select";
import {useTranslation} from "@/i18n/client";
import dynamic from "next/dynamic";
import {SearchQueryInput} from "@/components/forms/controls/SearchQueryInput";
import {DEFAULT_DISTANCE, distanceOptions} from "./SearchJobsFilterForm";
import Button from "@/components/atoms/Button";
import useTypewriter from "@/hooks/useTypewriter";

const schema = z.object({
  [AllowedJobSearchParams.Query]: z.string().optional(),
  [AllowedJobSearchParams.City]: z.string().optional(),
  [AllowedJobSearchParams.MaxDistance]: z.string().optional(),
  [AllowedJobSearchParams.Lat]: z.string().optional(),
  [AllowedJobSearchParams.Lng]: z.string().optional(),
});

const NoSSRSearchQueryInput = dynamic(async () => SearchQueryInput, {
  ssr: false,
});

const PLACEHOLDER_ANIMATION_SPEED = 75; // Higher is slower
const PLACEHOLDER_ANIMATION_DELAY = 2000; // Higher is slower

interface HomeSearchJobsFormProps {
  locale: AllowedLanguages;
}

export const HomeSearchJobsForm = ({locale}: HomeSearchJobsFormProps) => {
  const {t} = useTranslation(locale)
  const form = useZodForm({schema});
  const queryPlaceholder = useTypewriter([
    t("home.jobs.search_query.placeholder_1"),
    t("home.jobs.search_query.placeholder_2"),
    t("home.jobs.search_query.placeholder_3"),
  ], PLACEHOLDER_ANIMATION_SPEED, PLACEHOLDER_ANIMATION_DELAY);
  const locationPlaceholder = useTypewriter([
    t("home.jobs.search_location.placeholder_1"),
    t("home.jobs.search_location.placeholder_2"),
    t("home.jobs.search_location.placeholder_3"),
  ], PLACEHOLDER_ANIMATION_SPEED, PLACEHOLDER_ANIMATION_DELAY);

  const registerLatInput = form.register(AllowedJobSearchParams.Lat);
  const registerLngInput = form.register(AllowedJobSearchParams.Lng);

  const updateLocation = (
    {
      latitude,
      longitude,
      location,
    }: LocationValueSelectProps) => {
    form.setValue(AllowedJobSearchParams.City, location);
    form.setValue(AllowedJobSearchParams.Lat, latitude.toString());
    form.setValue(AllowedJobSearchParams.Lng, longitude.toString());
  };

  return (
    <Form
      form={form}
      action={`/${locale}/jobs`}
      method="GET"
      noValidate={true}
      className="relative z-1"
    >
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 xl:col-span-10 xl:col-start-2">
            <div className="-mt-10 mb-10 rounded-lg bg-yellow p-4 lg:-mt-16 lg:mb-16 lg:px-10 lg:py-8 xl:mb-24">
              <div className="grid grid-cols-24 gap-2 lg:!gap-6 ">
                <NoSSRSearchQueryInput
                  className="col-span-24 h-12 sm:col-span-11 md:col-span-12 lg:col-span-8 lg:h-16 xl:col-span-9 mb-3 md:mb-0"
                  i18n={{
                    clear_recent_searches: t("jobs.search_query.clear_recent_searches"),
                    label: t("jobs.search_query.label"),
                    new: t("jobs.search_query.new"),
                    placeholder: queryPlaceholder,
                    recent_searches: t("jobs.search_query.recent_searches"),
                  }}
                  searchHistoryBaseURL={`/${locale}/jobs`}
                />
                <div
                  className="col-span-24 flex h-12 sm:col-span-13 md:col-span-12 lg:col-span-9 lg:h-16 xl:col-span-8">
                  <NewLocationInput
                    onSelectValue={updateLocation}
                    className="form-input-lg w-full"
                    inputClassName="form-input-lg !rounded-r-none rounded-l-lg focus:ring-0 ring-0"
                    inputName="city"
                    i18n={{
                      placeholder: locationPlaceholder,
                      use_current_location: t("jobs.search_location.use_current"),
                    }}
                    locale={locale}
                  />

                  <Select
                    label={t('jobs.search_location.max_distance')}
                    name="max_distance"
                    className="divider-l flex items-center"
                    inputClassName="!rounded-l-none rounded-r-lg pl-4 pr-6 lg:pr-10 focus:ring-0 w-[88px] lg:!w-24 text-sm ring-0"
                    defaultValue={DEFAULT_DISTANCE}
                    options={distanceOptions}
                    hiddenLabel={true}
                  />

                  <input type="hidden" {...registerLatInput} />
                  <input type="hidden" {...registerLngInput} />
                </div>
                <div className="xxl:pl-4 col-span-24 lg:col-span-7 mt-2 lg:mt-0">
                  <Button
                    type="submit"
                    className={"btn btn-green btn-xl h-12 w-full lg:h-16"}
                    as="button"
                    hasLoader={true}
                  >
                    {t("jobs.search_in")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default HomeSearchJobsForm;
